<script setup>
import Layout from "../../layouts/main.vue";
import MethodService from "../../service/MethodService";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import DataForm from "./dataFormHanhChinh";
import { DVHCStore } from "../../stores/getDVHC";
import { FormInstance } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import Validate from "../../service/ValidService";
import toastr from "toastr";
import polyline from "google-polyline";

// lấy ngôn ngữ từ pinia store
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
import DataService from "../../service/DataService";

const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const value = ref([]);
const dvhc = DVHCStore();
const ruleFormRef = ref(FormInstance);
const validForm = reactive({ value: DataForm.tableRules.dataSearch.valid });
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
let loading = ref(true); // loading bảng
const validateFlag = ref(false);
// const pageSize4 = ref(20);
let idTinh = ref("");
const idDistrictAddPolygon = ref("");
const fullNameDistrict = ref("");
const dialogAddPolygon = ref(false);
const loadingBtn = ref(false);
const router = useRouter();
const route = useRoute();
const roles = ref([]);
const listType = ref(MethodService.copyObject(DataService).listDistrict);
const ruleFormAddPolygon = ref(FormInstance);
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const rules = reactive({
  bdy: [Validate.checkPolygonWithHTML, Validate.required],
});
const dataFormPolygon = reactive({
  bdy: "",
});
const fn_LayDanhSach = async () => {
  let dataFilter = {
    filters: tableRules.filters,
    fields: "id,disabled,code,name,provinceId,index,type,center",
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router
    .replace({
      name: "dvhcHuyen",
      query: {
        ...dataFilter,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.district.listAsync(dataFilter, {
      cacheAge: 1,
    });
    if (response?.result) {
      let res = changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
      loading.value = false;
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};

const changeData = (data) => {
  data.forEach((element) => {
    element.fullName = "";
    element.kinhDo = "";
    element.viDo = "";

    element.fullName = element.type + " " + element.name;
    element.kinhDo = element?.center?.lng;
    element.viDo = element?.center?.lat;
  });
  return data;
};

// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "dvhcHuyen",
    query: query,
  });
};
// tìm kiếm
const fn_TimKiem = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      tableRules.dataSearch.value["code"] = tableRules.dataSearch.value["code"]
        ? tableRules.dataSearch.value["code"].trim()
        : "";
      tableRules.dataSearch.value["type"] = tableRules.dataSearch.value["type"]
        ? tableRules.dataSearch.value["type"].trim()
        : "";
      tableRules.dataSearch.value["name"] = tableRules.dataSearch.value["name"]
        ? tableRules.dataSearch.value["name"].trim()
        : "";

      handleProvince(idTinh.value);

      tableRules.filters = MethodService.filterTable(
        JSON.stringify(tableRules.dataSearch)
      );
      tableRules.offset = 0;
      tableRules.sort = "";
      fn_LayDanhSach();
    } else return false;
  });
};

// function
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  fn_LayDanhSach();
};
const fn_tableSortChange = (column, tableSort) => {
  tableSort = tableRules;
  MethodService.tableSortChange(column, tableSort);
  fn_LayDanhSach();
};

const filtersProvince = () => {
  const idProvince = localStorage.getItem("idProvince");
  if (idProvince) {
    tableRules.filters = `provinceId=${idProvince}`;
    localStorage.removeItem("idProvince");
    value.value = idProvince;
    tableRules.dataSearch.value["provinceId"] = idProvince;
    tableRules.filters = MethodService.filterTable(
      JSON.stringify(tableRules.dataSearch)
    );
    tableRules.offset = 0;
    tableRules.sort = "";
  } else {
    tableRules.filters = "";
  }
};

const handleClick = (id) => {
  localStorage.setItem("idDistrict", id);
};

const handleProvince = (event) => {
  if (!event) {
    return;
  }

  tableRules.dataSearch.value["provinceId"] =
    tableRules.dataSearch.value["provinceId"].trim();
};

const fn_hashUrl = () => {
  if (route.query.limit) {
    tableRules.limit = parseInt(route.query.limit);
  }
  if (route.query.page) {
    tableRules.page = parseInt(route.query.page);
  }
  if (route.query.limit && route.query.page) {
    tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  }
  if (route.query.sort) {
    tableRules.sort = route.query.sort;
    tableRules.defaultSort[0] = tableRules.sort;
    if (tableRules.sort.indexOf("-") != -1) {
      tableRules.defaultSort[1] = "descending";
    } else {
      tableRules.defaultSort[1] = "ascending";
    }
  }
  if (route.query.filters) {
    tableRules.filters = route.query.filters;
    tableRules.dataSearch.value = MethodService.stringToObject(
      tableRules.filters
    );
  }
  if (route.query.showFormSearch) {
    tableRules.showFormSearch = route.query.showFormSearch;
  }
  if (route.query.offset) {
    tableRules.offset = route.query.offset;
  }
};

const showAddPolygon = (id, row) => {
  console.log("id huyện", id);
  idDistrictAddPolygon.value = id;
  fullNameDistrict.value = row.fullName;
  dialogAddPolygon.value = true;
  dataFormPolygon.bdy = "";
};

const addPolygon = (formEl) => {
  loadingBtn.value = true;
  validateFlag.value = true;
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      const bdy1 = JSON.parse(
        JSON.stringify(
          dataFormPolygon.bdy.split("</Polygon><Polygon>").map((polygon) =>
            polygon
              .replace(/[^\d., ]/g, "")
              .split(" ")
              .map((point) => point.split(","))
          )
        )
      );
      const district_object = {
        id: idDistrictAddPolygon.value,
        bdy: bdy1.map(polyline.encode),
      };
      try {
        const response = await mushroom.district.partialUpdateAsync(
          district_object
        );
        if (response.result == district_object.id)
          toastr.success(
            LanguageService?.[lang.value].toastr_add_polygon_success ??
              "toastr_add_polygon_success"
          );
        else
          toastr.warning(
            LanguageService?.[lang.value].toastr_not_change_warning ??
              "toastr_not_change_warning"
          );
        dialogAddPolygon.value = false;
      } catch (e) {
        console.error("Có lỗi: %o", e);
        MethodService.showError(e.code);
      } finally {
        loadingBtn.value = false;
      }
    } else loadingBtn.value = false;
  });
};

const getProvinceType = (provinceType) => {
  return t(`t_${provinceType.toLowerCase().split(" ").join("_")}`);
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    listType.value = MethodService.copyObject(DataService).listDistrict;
    if (validateFlag.value == true) {
      rules.value = MethodService.copyObject(rules);
    }
  }
);

onMounted(async () => {
  await mushroom.$auth.meAsync().then((response) => {
    roles.value = response.result.roles;
    console.log("3333", response.result.roles);
  });
  tableRules.limit = 20;
  filtersProvince();
  if (route.query.showFormSearch === "true") {
    fn_hashUrl();
  }
  if (parseInt(route.query.limit) && parseInt(route.query.limit) != 20) {
    tableRules.limit = parseInt(route.query.limit);
  }
  fn_LayDanhSach();
  dvhc.getProvince();
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-dvhc-huyen-hoa") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  ref="ruleFormRef"
                  :model="tableRules.dataSearch.value"
                  :rules="validForm.value"
                  label-position="top"
                  @submit.prevent
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-province')">
                        <el-select
                          v-model="tableRules.dataSearch.value['provinceId']"
                          filterable
                          clearable
                          :placeholder="$t('t-choose-province')"
                          @change="handleProvince"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in dvhc.provinceData"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-code-district')" prop="code">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['code']"
                          :placeholder="$t('t-place-code-district')"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-type-district')" prop="type">
                        <el-select
                          v-model="tableRules.dataSearch.value['type']"
                          filterable
                          clearable
                          :placeholder="$t('t-place-type-district')"
                          :no-match-text="$t('t-match-data')"
                          :no-data-text="$t('t-no-data')"
                        >
                          <el-option
                            v-for="item in listType"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-name-district')" prop="name">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t-place-name-district')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem(ruleFormRef)"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            border
            stripe
            :data="dataTables.value"
            style="width: 100%"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop="code"
              :label="$t('t-code-district')"
              align="center"
              width="110"
              :sortable="true"
            ></el-table-column>
            <el-table-column
              prop="type"
              :label="$t('t-type-district')"
              width="120"
              align="center"
              :sortable="true"
              sort-by="Quận"
            >
              <template #default="scope">
                {{ getProvinceType(scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('t-name-district')"
              align="center"
              :sortable="true"
            ></el-table-column>
            <el-table-column
              :label="$t('t-show-district')"
              width="140"
              align="center"
            >
              <template #default="scope">
                <el-tooltip
                  :content="$t('t-show-commune')"
                  effect="light"
                  :enterable="false"
                  placement="top"
                >
                  <button
                    type="button"
                    class="btn btn-soft-info waves-effect waves-light btn-sm"
                    @click="handleClick(scope.row.id)"
                  >
                    <router-link
                      class="link"
                      style="color: var(--el-button-text-color)"
                      to="/dvhc/xa"
                    >
                      <i class="ri-eye-line"></i>
                    </router-link>
                  </button>
                </el-tooltip>
                <el-tooltip
                  :content="$t('t-add-polygon')"
                  effect="light"
                  :enterable="false"
                  placement="top"
                >
                  <button
                    v-if="roles.includes('Admin')"
                    type="button"
                    class="btn btn-soft-success waves-effect waves-light btn-sm"
                    style="margin-left: 10px"
                    @click="showAddPolygon(scope.row.id, scope.row)"
                  >
                    <i class="ri-map-pin-add-line"></i>
                  </button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:page-size="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogAddPolygon"
      :title="$t('t-place-add-polygon', { msg: fullNameDistrict })"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleFormAddPolygon"
        :model="dataFormPolygon"
        :rules="rules"
        label-width="0px"
        class="demo-ruleForm"
      >
        <el-form-item prop="bdy">
          <el-input
            v-model="dataFormPolygon.bdy"
            :rows="4"
            type="textarea"
            :placeholder="$t('t-place-add-polygon')"
          />
        </el-form-item>
        <el-form-item style="margin-bottom: 0px">
          <div class="text-center w-100">
            <el-button
              type="primary"
              @click="addPolygon(ruleFormAddPolygon)"
              :loading="loadingBtn"
              >{{ $t("t_update") }}</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </Layout>
</template>

<style scoped>
.el-table {
  height: calc(100vh - 280px);
}
</style>
